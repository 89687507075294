body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.add-new-btn {
    min-width: 100px;
}

.min-width-btn {
    min-width: 80px;
}

.btn-group > .btn.active {
    z-index: 0;
}

.no-wrap {
    white-space: nowrap;
}

.confirm-dialog-overlay {
    z-index: 5000;
    background: rgba(0, 0, 0, 0.5);
}

.react-confirm-alert-body {
    max-width: 80vw;
}

.bg-light-yellow {
    background-color: #fff3cd;
}

.bg-light-gray {
    background-color: #f8f9fa;
}

.no-border {
    border: 0;
    td {
        border: 0;
    }
}

.text-purple {
    color: #d20062;
}

.small-font {
    font-size: 0.75rem; /* Adjust as needed */
}

.card-header-no-bottom {
    border-bottom: 0;
}

.accordion-header .accordion-button {
    font-weight: bold;
}

.nav-link {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.sb-sidenav-menu-heading {
    padding-top: 1rem !important;
    padding-bottom: 0.25rem !important;
}

.sb-sidenav-menu {
    overflow-y: auto;
}

/* Chrome, Safari, Edge */
.sb-sidenav-menu::-webkit-scrollbar {
    width: 12px; /* Adjust the width of the scrollbar */
}

.sb-sidenav-menu::-webkit-scrollbar-track {
    background: #f0f0f0; /* Track color */
    border-radius: 10px;
}

.sb-sidenav-menu::-webkit-scrollbar-thumb {
    background-color: #888; /* Handle color */
    border-radius: 10px;
    border: 3px solid #f0f0f0; /* Creates padding around the handle */
}

/* Firefox */
.sb-sidenav-menu {
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0; /* Handle and track color */
}

.unauth-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.unauth-layout-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.unauth-layout-logo {
    height: 40px;
    margin-right: 10px;
}

.unauth-layout-content {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
}

.unauth-layout-footer {
    padding: 0 20px;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    text-align: center;
}

.no-underline {
    text-decoration: none;
}

.compact-input {
    padding-top: 2px;
    padding-bottom: 2px;
}

.bg-gray {
    background-color: lightgray;
}

.leave-dates-box {
    max-height: 300px;
    overflow-y: auto;
}

.leave-approve-actions-panel {
    background-color: #fff3cd;
    border-radius: 5px;
    border: 2px solid #ccc;
}

.sb-nav-link-icon {
    width: 14px;
}

.bold-label {
    font-weight: 500;
}

.bold-header {
    font-weight: 600;
}

.text-clickable {
    cursor: pointer;
}

.strikethrough {
    text-decoration: line-through;
}

.compact-card-body p {
    margin-bottom: 0.5rem; /* Adjust the value as needed */
}
